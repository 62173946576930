import React from 'react'
import { Link } from 'react-router-dom'

import styles from './index.module.scss'

const BowiLink = ({content, linkTo}) => {

    return(
        <Link className={styles.link} to={linkTo}>{content}</Link>
    )

}

export default BowiLink