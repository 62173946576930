export const SET_INVOICE_INFO = 'SET_INVOICE_INFO';
export const GET_INVOICE_INFO = 'GET_INVOICE_INFO';


const initialState = {
    currentInvoice: {
        energy: "",
        connected: "",
        finished: "",
        price: 0,
        currency: ""
    },
    invoices: [],
    numberOfInvoices: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICE_INFO:
      return{
        ...state,
        currentInvoice:{
            energy: action.payload.energy,
            connected: action.payload.connected,
            finished: action.payload.finished,
            price: action.payload.price,
            currency: action.payload.currency
        }
      }
    default:
      return state;
  }
}


export const getInvoiceInfo = (token, code, reference) => ({
  type: GET_INVOICE_INFO,
  token: token,
  code: code,
  reference: reference
});