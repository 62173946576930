import React, {useState} from 'react';
import { useIntl } from 'react-intl';
import Time from 'assets/onboarding/onbaording-time.svg'
import Charge from 'assets/onboarding/onboarding-charge.svg'
import Review from 'assets/onboarding/onboarding-review.svg'
import BowiLink from "components/BowiLink"
import SwipeableViews from 'react-swipeable-views';

//import constants
import {
    HOME_PATH,
  } from "constants.js";

import styles from "./index.module.scss"



const Page = ({title, content, icon}) => {

    const[newTitle, setNewTitle] = useState(title)
    const[newIcon, setNewIcon] = useState(icon)
    const[newContent, setNewContent] = useState(content)

    return(
        <div className={styles.pageWrapper}>
            <div className={styles.title}>{newTitle}</div>
            <img className={styles.image} src={newIcon} alt="icon"/>
            <div className={styles.contentWrapper}>
                {newContent.map((item, index) => {
                    return(
                        <div
                            key = {index}
                            className= {styles.content} 
                            dangerouslySetInnerHTML={{ __html: item }} 
                        />
                    )
                })}
            </div>
        </div>
    )
}

const TutorialPage = () => {
    
    const intl = useIntl();
    const [page, setPage] = useState(0)


    const content = [
        { 
            title: intl.formatMessage({id : "tutorial.title.one"}),
            icon: Charge,
            content: [
                intl.formatHTMLMessage({id : "tutorial.content.one.line-one"}),
                intl.formatHTMLMessage({id : "tutorial.content.one.line-two"}),
                intl.formatHTMLMessage({id : "tutorial.content.one.line-three"})
            ]
        },
        { 
            title: intl.formatMessage({id : "tutorial.title.two"}),
            icon: Time,
            content: [
                intl.formatHTMLMessage({id : "tutorial.content.two.line-one"}),
                intl.formatHTMLMessage({id : "tutorial.content.two.line-two"})
            ]
        }, 
        { 
            title: intl.formatMessage({id : "tutorial.title.three"}),
            icon: Review,
            content: [
                intl.formatHTMLMessage({id : "tutorial.content.three.line-one"}),
                intl.formatHTMLMessage({id : "tutorial.content.three.line-two"}),
                intl.formatHTMLMessage({id : "tutorial.content.three.line-three"})
            ]
        }];
    
    const handleBullets = (index) => {
        setPage(index)
    }

    return (
        <div className={styles.container}>
            <SwipeableViews
                onChangeIndex={(index) => {handleBullets(index)}}
            >
                <Page
                    className={styles.page}
                    title={content[0].title} 
                    icon={content[0].icon} 
                    content={content[0].content}
                />
                <Page 
                    className={styles.page}
                    title={content[1].title} 
                    icon={content[1].icon} 
                    content={content[1].content}
                />
                <Page 
                    className={styles.page}
                    title={content[2].title} 
                    icon={content[2].icon} 
                    content={content[2].content}
                />
            </SwipeableViews>
            <div className={styles.dots}>
                <div className={page === 0 ? styles.active : styles.dot}></div>
                <div className={page === 1 ? styles.active : styles.dot}></div>
                <div className={page === 2 ? styles.active : styles.dot}></div>
            </div>
            <div className={styles.link}>
                <BowiLink
                    content={ 
                        page === 2 
                        ? intl.formatMessage({id : "general.label.done"})
                        : intl.formatMessage({id : "general.label.skip"})} 
                    linkTo={HOME_PATH}
                />
            </div>
        </div>
    );
}

export default TutorialPage