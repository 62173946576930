import React from "react";
import { CircularProgress } from '@material-ui/core';

import styles from './index.module.scss'


const OnLoadingComponent = () => {

    return(
        <div className={styles.spinnerWrapper}>
            <div className={styles.spinner}>
                <CircularProgress
                    color="primary"
                    size={80}
                />  
            </div>
        </div>
    )

}

export default OnLoadingComponent