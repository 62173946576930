import { connect } from 'react-redux';
import AuthWrapper from 'components/AuthWrapper';

import { 
  authLogin
} from 'reducers/auth'

import { 
  getUserInfo
} from 'reducers/user'

export default connect(
  ({ auth: {
    token,
    expires,
    firstLogin,
    loggedIn
  } }) => ({
    token,
    expires,
    firstLogin,
    loggedIn
  }),
  {
    authenticate: authLogin,
    getUserInfo: getUserInfo
  }
)(AuthWrapper);

