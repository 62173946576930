import uniqueId from 'lodash.uniqueid';

export const NOTIFICATIONS_ADD = 'NOTIFICATIONS_ADD';
export const NOTIFICATIONS_REMOVE = 'NOTIFICATIONS_REMOVE';
export const NOTIFICATIONS_REMOVE_ALL = 'NOTIFICATIONS_REMOVE_ALL';

export const addNotification = ({
  id = '',
  error = false,
  info = false,
  removable = true,
  autoClear = true,
  success = false,
  message = '',
  messageId = '',
  messageValues = {}
}) => ({
  type: NOTIFICATIONS_ADD,
  payload: {
    id: id || uniqueId('notification_'),
    error,
    info,
    removable,
    autoClear,
    message,
    messageId,
    messageValues,
    success
  }
});

export default (state = [], action) => {
  switch (action.type) {
    case NOTIFICATIONS_ADD:
      const notifications = [];
      const map = new Map();

      for (const item of state) { // eslint-disable-line no-unused-vars
        if (!map.has(item.id)) {
          map.set(item.id, true);

          notifications.push({ ...item });
        }
      }

      return notifications
        .filter(({ autoClear }) => autoClear !== true)
        .concat(action.payload);
    case NOTIFICATIONS_REMOVE:
      if (action.id === null) {
        return state.slice(1);
      }
      return state.filter(({ id }) => id !== action.id);
    case NOTIFICATIONS_REMOVE_ALL:
      return state.filter(({ id }) => id === 'offline_notification');
    default:
      return state;
  }
}

export const removeAllNotifications = () => ({
  type: NOTIFICATIONS_REMOVE_ALL
});

export const removeNotification = (id = null) => ({
  type: NOTIFICATIONS_REMOVE,
  id
});
