import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { get, post, deleteHttp, putHttp } from 'utils/http';
import { handleBackendError } from 'utils/errorHandling';
import { tokenErrorHandling } from 'utils/tokenErrorHandling'

import {
  IS_VALID_SET
} from 'reducers/station'

import {
    API_USER
  } from 'constants.js';

  import {
    GET_USER_INFO,
    SET_USER_INFO
  } from 'reducers/user';

  import {
    NOTIFICATIONS_REMOVE_ALL
  } from 'reducers/notifications';

function* fetchUserInfo({token, userEmail, userPassword}) {

  let header = {}
  let newToken

  if (!token){
    newToken = localStorage.getItem('token')
  }
  
  if(token !== null || newToken){
    header = {
      "Authorization" : `Bearer ${token ? token : newToken}`
    }
  }

  try{
    const response = yield call(get, API_USER, header)
    yield put({
      type: SET_USER_INFO,
      payload:{
        firstName: response.firstName,
        lastName: response.lastName,
        email: response.email,
        activeSession: response.activeSession,
        code: response.code,
        physicalReference: response.physicalReference,
        authorizationReference: response.authorizationReference
      }
    })

    if(response.activeSession){
      localStorage.setItem('isCharging', response.activeSession)
      localStorage.setItem('reference', response.authorizationReference);
      localStorage.setItem('lsPhysicalReference', response.physicalReference);
      localStorage.setItem('code', response.code);
    }

    if(response.authorizationReference){
      yield put({
        type: IS_VALID_SET,
        payload: true
      })
    }

  } catch (error) {
    yield put(tokenErrorHandling(yield (error), "error.stopCharging.label" || {}));
  }
}

function* watchGetUserInfo() {
  yield takeLatest(GET_USER_INFO, fetchUserInfo);
}

export default function* root() {
    yield all([
      fork(watchGetUserInfo)
    ]);
  }
