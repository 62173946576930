import { connect } from 'react-redux';
import KeycloakLoginButton from 'components/KeycloakLoginButton';

import { 
  isValidSet
} from 'reducers/station'

export default connect(
  ({ station: {}}) => ({}),
  {
    setIsValid: isValidSet
  }
)(KeycloakLoginButton);

