import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { get, post, deleteHttp, putHttp } from 'utils/http';
import { handleBackendError } from 'utils/errorHandling';
import { addNotification } from 'reducers/notifications';

import { 
  GET_USER_INFO
} from 'reducers/user'

import {
  API_AUTH,
} from 'constants.js';

import auth, {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_SET_STATUS,
  AUTH_AUTHENTICATE,
  AUTH_SET_TOKEN,
  AUTH_SET_LOGIN
} from 'reducers/auth';

function* authLogin({userToken, expires}) {
  
  let firstLogin = false

  if(userToken){
    let fL = localStorage.getItem('firstLogin');
    if(fL === null){
      firstLogin = true
    }
    localStorage.setItem('firstLogin', firstLogin);
    localStorage.setItem('token', userToken);
    
    yield put({
      type : AUTH_SET_LOGIN,
      payload : {firstLogin: firstLogin, loggedIn: true}
    })
    yield put({
      type: AUTH_SET_TOKEN,
      payload: {userToken, expires}
    })

    yield put({
      type: GET_USER_INFO
    })
  }
}

function* watchAuthLogin() {
  yield takeLatest(AUTH_AUTHENTICATE, authLogin);
}


export default function* root() {
  yield all([
    fork(watchAuthLogin)
  ]);
}

