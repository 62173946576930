import { connect } from 'react-redux';
import QrScanner from 'components/QrScanner';

import { 
  sendCode
} from 'reducers/station'

export default connect(
  ({ station: {
    code,
    validCode
    }
  }) => ({
    code,
    validCode
  }),
  {
    submitCode: sendCode
  }
)(QrScanner);

