import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Avatar from '@material-ui/core/Avatar';
import BulChargeLogo from 'assets/logo/mainLogo.png'
import BowiButton from 'components/BowiButton'
import IconButton from '@material-ui/core/IconButton';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import styles from './index.module.scss'


const InstallPrompt = ({handleInstall}) => {

    const [close, setClose] = useState(false)

    const handleClose = () => {
        localStorage.setItem('installable', false)
        setClose(true)
    }

    const intl = useIntl();

    return (
        close 
        ? null
        :<div className={styles.container}>
            <p className={styles.installDescription}>
                {intl.formatMessage({id : "installPromptDescription"})}
            </p>
            <div className={styles.close}>
                <IconButton
                    onClick={ () => {handleClose()}}
                    color="primary" 
                    component="span"
                >
                    <HighlightOffRoundedIcon />
                </IconButton>
            </div>
            <Avatar
                variant="rounded"
                className={styles.avatar}
                alt="BulChargeLogo" 
                src={BulChargeLogo}
            />
            <BowiButton
                type="primary"
                content={intl.formatMessage({id: "installPropmtAddToHomeScreen"})}
                className={styles.button}
                handleClick={handleInstall}
            />
        </div>
    )
}

export default InstallPrompt

