import { all, fork } from 'redux-saga/effects';

// import sagas from dir
import auth from './auth';
import charging from './charging';
import station from './station';
import user from './user';
import invoices from './invoices';


// export default saga
export default function* root (){
    yield all([
        fork(auth),
        fork(charging),
        fork(user),
        fork(invoices),
        fork(station)
    ]);
}