import { connect } from 'react-redux';
import StopChargerHeader from 'components/StopChargerHeader';

export default connect(
  ({ charging: {
      status
    },
    user: {
      physicalReference
    }
  }) => ({
    status,
    physicalReference
  })
)(StopChargerHeader);

