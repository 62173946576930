import React, { useEffect, useState } from 'react'
import { Link, useLocation} from 'react-router-dom'
import {
    HomeIconEmpty, 
    HomeIconFull, 
    AccountIconEmpty, 
    AccountIconFull
} from 'components/IconPack'

import {
    HOME_PATH,
    PROFILE_PATH,
    TUTORIAL_PATH,
    CHARGER_PATH,
    INVOICE_PATH
} from 'constants.js'

import styles from './index.module.scss'


class BowiFooterMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {location: HOME_PATH};
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState({ location: this.props.location })
        }
    }

    handleClick = () => {
      this.props.setIsValid()
      localStorage.removeItem('physicalReference');
      localStorage.removeItem('status');
      localStorage.removeItem('hours');
      localStorage.removeItem('minutes');
    }

    render() {
      return (
        <div className={styles.footerContainer}>
          {/* <div onClick={this.state.location !== CHARGER_PATH && this.state.location !== INVOICE_PATH ? this.handleClick : null}> */}
          <div onClick={this.handleClick}>
            {/* {this.state.location !== CHARGER_PATH && this.state.location !== INVOICE_PATH */}
              <Link to={HOME_PATH} className={styles.icon}>
                  {
                      this.state.location === HOME_PATH 
                      ? <HomeIconFull/>
                      : <HomeIconEmpty/>
                  }
                  <p>Home</p>
              </Link>
              {/* :<a className={styles.icon}>
                <HomeIconEmpty/>
                <p>Home</p>
              </a>
            } */}
          </div>
          {/* {this.state.location !== CHARGER_PATH && this.state.location !== INVOICE_PATH */}
            <Link to={PROFILE_PATH} className={styles.icon}>
              {
                  this.state.location === PROFILE_PATH 
                  ? <AccountIconFull/>
                  : <AccountIconEmpty/>
              }
              <p>Account</p>
            </Link>
            {/* :<a className={styles.icon}>
              <AccountIconEmpty/>
             <p>Account</p>
            </a>
          } */}
        </div>
      );
    }
  }



const FooterMenu = ({setIsValid}) => {

    const location = useLocation();
    const [prevLoggedIn, setPrevLoggedIn] = useState(true)

    useEffect(() => {
      let token = localStorage.getItem("token");
        if (token === null) {
          setPrevLoggedIn(false);
        }
      }, []);

    useEffect(() => {
      }, [location])
    
    return prevLoggedIn
        ?   <div>
                {location.pathname !== TUTORIAL_PATH
                    ? <BowiFooterMenu setIsValid={setIsValid} location={location.pathname}/>
                    : null
                }
            </div>
        : null
}

export default FooterMenu