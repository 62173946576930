import React,{useEffect, useState} from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress'

import styles from './index.module.scss'


const StopChargerHeader = ({physicalReference, status}) => {

    const [lsPhysicalReference, setLsPhysicalReference] = useState("")

    useEffect(() => {
        let localStoragePhysicalReference = localStorage.getItem("physicalReference");
        setLsPhysicalReference(localStoragePhysicalReference);
      }, []);

    return(
        <div className={styles.container}>
            <div>{physicalReference ? physicalReference : lsPhysicalReference}</div>
            { status
                ?<div className={status === "CONNECTED" 
                    ? styles.ok 
                    : styles.error}>{status}</div>
                :<div className={styles.loading}>
                    <CircularProgress color="secondary" size={16}/>
                </div>
            }
        </div>
    )
} 

export default StopChargerHeader