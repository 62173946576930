import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useKeycloak } from '@react-keycloak/web'
import BowiButton from 'components/BowiButton'
import BowiLink  from 'components/BowiLink'
import ProfileView from 'containers/ProfileView'


import {
    TUTORIAL_PATH,
    HOME_PATH
} from 'constants.js'

import styles from './index.module.scss';


const UserProfile = () => {


    const { keycloak } = useKeycloak()

    const onClickHandle = () => {
        window.location.href = HOME_PATH
        keycloak.logout();
        localStorage.removeItem('token');
        localStorage.removeItem('reference');
        localStorage.removeItem('physicalReference');
        localStorage.removeItem('status');
        localStorage.removeItem('code');
        localStorage.removeItem('scannedQRLink');
        localStorage.removeItem('isCharging');
        localStorage.removeItem('loggedIn');
    }

    const intl = useIntl();

    const [profileView, setProfileView] = useState(false)

    const handleProfileView = () => {
        setProfileView(!profileView)
    }

    return (
        profileView === false
        ?<div className={styles.container}>
            <div className={styles.linkWrapper}>
                <BowiLink
                    className={styles.link}
                    linkTo={TUTORIAL_PATH}
                    content={intl.formatMessage({id : "general.label.howDoesItWork"})}
                />
            </div>
            <BowiButton
                type='ternary'
                content={intl.formatMessage({id : "general.label.profileInfo"})}
                handleClick={handleProfileView}
            />
            <BowiButton
                className={styles.logOut}
                type='ternary'
                content={intl.formatMessage({id : "general.label.logOut"})}
                handleClick={onClickHandle}
            />
        </div>
        : <ProfileView
            handleBack={handleProfileView}
        />
    )
}

export default UserProfile