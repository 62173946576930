import React from 'react'
import {useIntl} from 'react-intl'

import styles from './index.module.scss'


const Processing = () => {

    const intl = useIntl()

    return(
        <div className={styles.container}>
            <p className={styles.processing}>{intl.formatMessage({id : "processing.label.title"})}</p>
            <div className={styles.gooey}>
                <span className={styles.dot}></span>
                <div className={styles.dots}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    )   
}

export default Processing