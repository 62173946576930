const defaultHeaders = {
  'Content-Type': 'application/json'
};

const noop = () => {};

const handleResponse = (response) => new Promise((resolve, reject) => {
  const { headers, status } = response;
  const contentType = headers.get('Content-Type');
  const isJSON = contentType && contentType.indexOf('application/json') !== -1;

  // 'ok' property states whether the response was successful (status in the range 200-299) or not
  return response.ok === true
    ? resolve(isJSON
      ? response.json().then(body => ({ ...body, status }))
      : noop()
    )
    : reject(isJSON
      ? response.json().then(body => ({ ...body, status }))
      : response
    )
});

const makeRequest = (url, headers, body, options) => (method) => fetch(url, {
  method,
  headers: {
    ...defaultHeaders,
    ...headers
  },
  body,
  ...options
}).then(handleResponse);

export const get = (url, headers, options) => makeRequest(url, headers, options)('GET');
export const post = (url, body, headers, options) => makeRequest(url, headers, body, options)('POST');
export const deleteHttp = (url, body, headers, options) => makeRequest(url, headers, body, options)('DELETE');
export const putHttp = (url, body, headers, options) => makeRequest(url, headers, body, options)('PUT');
