import React, {useState} from 'react';
import { useIntl } from 'react-intl';
import QrReader from 'react-qr-reader';
import { ArrowLeftIcon } from 'components/IconPack'
import styles from './index.module.scss'
import { Redirect, Route } from 'react-router-dom';
import BowiButton from 'components/BowiButton';

import {
    CHARGER_PATH,
} from "constants.js";

const QrScanner = ({handleScanning, submitCode, validCode}) => {

    const intl = useIntl();
    const [qrScanned, setQrScanned] = useState(false)
    
    const handleScan = (data) => {
        if (data) {
            submitCode(data)
            setQrScanned(true)
        }
      }
    
    const handleError = (err) => {
        console.error(err)
      }

    return(
        <div className={styles.container}>
            <QrReader
                resolution={1200}
                delay={3000}
                onError={handleError}
                onScan={handleScan}
                className={styles.scanner}
            />
            <div className={styles.infoBanner}>
                <p className={styles.info}>
                    {intl.formatMessage({ id: "qrScanner.qrInfo"})}
                </p>
                <BowiButton
                    type="primary"
                    content={intl.formatMessage({ id: "general.label.back"})}
                    handleClick={handleScanning}
                    dispable={false}
                />
                <Route>{qrScanned && validCode ? <Redirect to={CHARGER_PATH}/> : null}</Route>
            </div>
      </div>
    )
}

export default QrScanner