import React from 'react'
import { useIntl } from 'react-intl';
import BowiButton from 'components/BowiButton'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';


import styles from './index.module.scss';
import { capitalize } from '@material-ui/core';

const ProfileView = ({firstName, lastName, email, handleBack}) => {

    const intl = useIntl()

    return (
        <div className={styles.wrapper}>
            <List className={styles.list}>
                <ListItem className={styles.listItem}>
                    <ListItemAvatar className={styles.listAvatar}>
                    <Avatar className={styles.avatar}>
                        <AccountCircleIcon/>
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                        className="info"
                        primary={intl.formatMessage({ id: "general.label.userName"})} 
                        secondary={`${capitalize(firstName)} ${capitalize(lastName)}`}
                    />
                </ListItem>
                <ListItem className={styles.listItem}>
                    <ListItemAvatar className={styles.listAvatar}>
                    <Avatar className={styles.avatar}>
                        <EmailIcon/>
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                        className="info"
                        primary={intl.formatMessage({ id: "general.label.email"})} 
                        secondary={email}
                    />
                </ListItem>
            </List>
            <BowiButton
                className={styles.back}
                type='ternary'
                content={intl.formatMessage({id : "general.label.back"})}
                handleClick={handleBack}
            />
        </div>
    )
}

export default ProfileView