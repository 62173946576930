import React,{useEffect, useState} from 'react'

import styles from './index.module.scss'


const ChargerHeader = ({ physicalReference, status}) => {

    const [lsStatus, setLsStatus] = useState("")
    const [lsPhysicalReference, setLsPhysicalReference] = useState("")


    useEffect(() => {
        let localStorageStatus = localStorage.getItem("status");
        let localStoragePhysicalReference = localStorage.getItem("physicalReference");
        setLsStatus(localStorageStatus);
        setLsPhysicalReference(localStoragePhysicalReference);
      }, []);

    return(
        <div className={styles.container}>
            <div>{physicalReference ? physicalReference : lsPhysicalReference}</div>
            <div className={(status || lsStatus) === "AVAILABLE" 
                ? styles.ok 
                : styles.error}>{status ? status : lsStatus}</div>
        </div>
    )
} 

export default ChargerHeader