export const CHARGING_INFO_SET = 'CHARGING_INFO_SET';
export const START_CHARGING = 'START_CHARGING';
export const STOP_CHARGING = 'STOP_CHARGING';
export const SET_REFERENCE = 'SET_REFERENCE';
export const SET_CHARGING_STOP = 'SET_CHARGING_STOP';
export const UPDATE_CHARGING_DATA = 'UPDATE_CHARGING_DATA';

const initialState = {
  status: "",
  energy: 0,
  connected: "",
  finishes: "",
  hideStopButton: false,
  sessionEnded: false,
  reference: "",
  chargingStop: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHARGING_INFO_SET:
      return{
        ...state,
        status: action.payload.status,
        energy: action.payload.energy,
        connected: action.payload.connected,
        hideStopButton: action.payload.hideStopButton,
        sessionEnded: action.payload.sessionEnded,
        finishes: action.payload.finishes
      }
    case SET_REFERENCE:
      return{
        ...state,
        reference: action.payload
      }
    case SET_CHARGING_STOP:
      return{
        ...state,
        chargingStop: action.payload
      }
    default:
      return state;
  }
}

export const startCharging = (token, code, hours, minutes) => ({
  type: START_CHARGING,
  token: token,
  code: code,
  hours: hours,
  minutes: minutes

})

export const stopCharging = (token, code, reference) => ({
  type: STOP_CHARGING,
  token: token,
  code: code,
  reference: reference
})

export const updateChargingStatus = () => ({
  type: SET_CHARGING_STOP,
  payload: false
})

export const updateChargingData = (reference, code) => ({
  type: UPDATE_CHARGING_DATA,
  reference: reference,
  code: code
})

export const resetChargingDataToInitialState = () => ({
  type: CHARGING_INFO_SET,
  payload:{
    status: "",
    energy: 0,
    connected: "",
    finishes: "",
    hideStopButton: false,
    sessionEnded: false,
    reference: "",
    chargingStop: false
  }
})