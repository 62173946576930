import { connect } from 'react-redux';
import Charger from 'pages/Charger';

import { 
  sendCode,
  isValidSet
} from 'reducers/station'

export default connect(
  ({ station: {
      isCharging
    },
    user:{
      physicalReference,
    }
  }) => ({
    isCharging,
    physicalReference
  }),
  {
    submitCode: sendCode,
    setIsValid: isValidSet
  }
)(Charger);

