import { connect } from 'react-redux';
import Invoice from 'components/Invoice';

import { 
  getUserInfo
} from 'reducers/user'

import { 
  updateChargingStatus,
  resetChargingDataToInitialState
} from 'reducers/charging'

import { 
  getInvoiceInfo,
} from 'reducers/invoices'

import { 
  isValidSet,
  clearIsCharging
} from 'reducers/station'
import { get } from 'utils/http';

export default connect(
  ({ invoices: {
      currentInvoice
    },
    user:{
      authorizationReference,
      chargerCode
    }
  }) => ({
    currentInvoice,
    authorizationReference,
    chargerCode
  }),
  {
    resetChargingDataToInitialState: resetChargingDataToInitialState,
    getUserInfo: getUserInfo,
    updateChargingStatus: updateChargingStatus,
    setIsValid: isValidSet,
    clearIsCharging: clearIsCharging,
    getInvoiceInfo: getInvoiceInfo

  }
)(Invoice);

