import { login } from 'reducers/auth';
import { addNotification } from 'reducers/notifications';
 

export const tokenErrorHandling = (error) => {

    if(error.status === 401){
        return logOut
    } else {
        return addNotification({
            id: 'backend_error_notification',
            error: true,
            autoClear: true,
            messageId: "error.qr.label.2001",
            success: false
            });
    }
};

const logOut = () => {
    localStorage.clear()
    window.location.reload();
}