import { connect } from 'react-redux';
import StartCharging from 'components/StartCharging';

import { 
  startCharging,
  updateChargingData
} from 'reducers/charging'

export default connect(
  ({ charging: {
    status,
    energy,
    connected,
    finishes,
    hideStopButton,
    sessionEnded,
    reference
    },
  }) => ({
    status,
    energy,
    connected,
    finishes,
    hideStopButton,
    sessionEnded,
    reference,
  }),
  {
    startCharging: startCharging,
  }
)(StartCharging);

