import { NOTIFICATION_TIMEOUT } from 'constants.js';
import {
  NOTIFICATIONS_ADD,
  removeNotification
} from 'reducers/notifications';

export default store => next => action => {
  const { type, payload } = action;

  switch (type) {
    case NOTIFICATIONS_ADD:
      if (payload.autoClear === true) {
        window.setTimeout((id) => {
          store.dispatch(removeNotification(id))
        }, NOTIFICATION_TIMEOUT, payload.id);
      }

      break;
    // TODO: should clear all notifications on log-out
    // no default
  }

  return next(action);
}
