import React from 'react';


import styles from './index.module.scss'

const BowiButton = ({type, content, handleClick}) => {

  let style;

  if(type === 'secondary'){
    style = styles.secondary
  } else if ( type === 'ternary') {
    style = styles.ternary
  } else if (type === 'disabledPrimary'){
    style = styles.disabledPrimary
  } else if (type === 'disabledSecondary'){
    style = styles.disabledSecondary
  } else {
    style = styles.primary
  }

  return(
    <div className={style} onClick={handleClick}>
      <p>{content}</p>
    </div>
  )

}

export default BowiButton