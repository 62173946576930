import { connect } from 'react-redux';
import StopCharging from 'components/StopCharging';

import { 
  stopCharging,
  updateChargingData
} from 'reducers/charging'

export default connect(
  ({ charging: {
    status,
    energy,
    connected,
    finishes,
    hideStopButton,
    sessionEnded,
    reference,
    chargingStop
    },
    user:{
      chargerCode,
      activeSession
    }
  }) => ({
    status,
    energy,
    connected,
    finishes,
    hideStopButton,
    sessionEnded,
    reference,
    chargingStop,
    chargerCode,  
    activeSession
    
  }),
  {
    handleStopCharging: stopCharging,
    updateChargingData: updateChargingData

  }
)(StopCharging);

