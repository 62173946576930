import React from 'react';
import TutorialPage from 'components/TutorialPage'

import styles from "./index.module.scss"

const Tutorial = () => {
    return (
        <div className={styles.wrapper}>
            <TutorialPage/>
        </div>
    )
}

export default Tutorial;