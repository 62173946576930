import React from 'react';
import FormattedMessage from 'react-intl/lib/components/message';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import styles from './index.module.scss';

const Notification = ({ error, message, messageId, onClose, success }) => (
  <Snackbar className={styles.wrapper} open>
    <SnackbarContent
      classes={{ root: styles[error ? 'error' : success ? 'success' : 'info'] }}
      message={
        <span className={styles.message}>
          { error
            ? <CancelOutlinedIcon className={styles.errorIcon} />
            : success
              ? <CheckCircleOutlineIcon className={styles.successIcon} />
              : <InfoOutlinedIcon className={styles.infoIcon} />
          }
          {message || (messageId && <FormattedMessage id={messageId} />)}
        </span>
      }
      action={
        <IconButton 
          key="close" 
          aria-label="close" 
          onClick={onClose}>
          <CloseIcon 
            color="inherit"
          />
        </IconButton>
      }
    />
  </Snackbar>
);

export default Notification;
