import React, { useState } from "react";
import {useIntl} from "react-intl"
import Picker from 'react-mobile-picker';

import styles from './index.module.scss'

class TimePicker extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        valueGroups: {
          hour: localStorage.getItem('hours') ? localStorage.getItem('hours') : '0',
          minutes: localStorage.getItem('minutes') ? localStorage.getItem('minutes') : '00',
        }, 
        optionGroups: {
          hour: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
          minutes: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']
        }
      };

    }
  
    // Update the value in response to user picking event
    handleChange = (name, value) => {
      this.setState(({valueGroups}) => ({
        valueGroups: {
          ...valueGroups,
          [name]: value
        }
      }));
      this.props.handleTime(this.state.valueGroups)
      localStorage.setItem('hours', this.state.valueGroups.hour);
      localStorage.setItem('minutes', this.state.valueGroups.minutes);
    };

    render() {
      const {optionGroups, valueGroups} = this.state;
  
      return (
        <Picker
          className={styles.picker}
          itemHeight={48}
          optionGroups={optionGroups}
          valueGroups={valueGroups}
          onChange={this.handleChange} />
      );
    }
  }


const BowiNumberSlider = () => {

    const intl = useIntl()
    const [time, setTime] = useState("0000")

    const handleTime = (pickedTime) => {

    }

    return(
        <div className={styles.container}>
            <p>{intl.formatMessage({id : "bowi.slider.header"})}</p>
            <TimePicker handleTime={handleTime}/>
            <div className={styles.labels}>
              <p>Hours</p>
              <p>Minutes</p>
            </div>
        </div>
    )
}

export default BowiNumberSlider