import React, {useState, useEffect, useRef} from "react"
import {useIntl} from "react-intl"

import styles from "./index.module.scss"

const ChargingInfo = ({energy, connected, finishes}) => {


    const intl = useIntl()
    const secondsPassed = useRef(0);
    const [time, setTime] = useState(new Date().toLocaleTimeString());;
    const [hours , setHours] = useState(0);
    const [minutes , setMinutes] = useState(0);
    const [seconds , setSeconds] = useState(0);

    useEffect(() => {

        const timeout = setTimeout(() => {
            let time = connected.split(":")
            let currentTime = time[0]*60*60 + time[1]*60
            secondsPassed.current = secondsPassed.current > currentTime ? secondsPassed.current : currentTime
            const date = new Date()
            secondsPassed.current = secondsPassed.current + 1;
            setTime(date.toLocaleTimeString());
            setSeconds(secondsPassed.current % 60)
            setHours(Math.trunc((secondsPassed.current / 60) / 60))
            setMinutes(Math.trunc((secondsPassed.current / 60) % 60))
        }, 1000);
        return () => {
          clearTimeout(timeout);
        }
    }, [time]);

    const date = new Date(Date.now());
    date.setHours(isNaN(hours) ? 0 : hours);
    date.setMinutes(isNaN(minutes) ? 0 : minutes);
    date.setSeconds(isNaN(seconds) ? 0 : seconds);

    return(
        <div className={styles.container}>
            <div className={styles.energy}>
                <p className={styles.title}>{intl.formatMessage({id : "charging.label.energy"})}</p>
                <p className={styles.value}>{energy} kW</p>
            </div>
            <div className={styles.connected}>
                <p className={styles.title}>{intl.formatMessage({id : "charging.label.connected"})}</p>
                <p>{new Intl.DateTimeFormat('en-US', {hour: '2-digit', hour: 'numeric', minute: 'numeric', second: 'numeric', hourCycle: 'h23'}).format(date)}</p>
            </div>
            <div className={styles.finish}>
                <p className={styles.title}>{intl.formatMessage({id : "charging.label.finished"})}</p>
                <p className={styles.value}>{new Intl.DateTimeFormat('en-US', {hour: '2-digit', hour: 'numeric', minute: 'numeric', hourCycle: 'h23'}).format(finishes)}</p>
            </div>
        </div>
    )
}

export default ChargingInfo