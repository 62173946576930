import React , {useState, useEffect} from 'react'
import Notification from 'components/Notifications/Notification'
import BowiNumberSlider from 'components/BowiNumberSlider'
import ChargerHeader from 'components/ChargerHeader'
import BowiButton from 'components/BowiButton'
import {useIntl} from 'react-intl'


import styles from './index.module.scss'


const StartCharging = ({
    isAvailable, 
    isOpen, 
    handleCloseError, 
    startCharging,
}) => {



    const intl = useIntl()

    const handleStart = () => {
        let token = localStorage.getItem('token')
        let code = localStorage.getItem('code')
        let hours = localStorage.getItem('hours')
        let minutes = localStorage.getItem('minutes')
        startCharging(token, code, hours, minutes)
    }

    return(
        <div className={styles.container}>
                <ChargerHeader/>
                <BowiNumberSlider/>
                <div className={styles.buttonContainer}>
                    <BowiButton
                        type= {!isAvailable ? "disabledPrimary" : "primary"}
                        content={intl.formatMessage({id : "charging.lable.start"})}                
                        handleClick={!isAvailable ? 
                                        !isOpen ?
                                            handleCloseError
                                            :null 
                                        : handleStart}
                    />
                </div>
                { !isAvailable && isOpen
                    ? <Notification
                        className={styles.notification}
                        error={false}
                        message={intl.formatMessage({id: "error.charging.lable.notAvailable"})}
                        onClose={handleCloseError}
                        success={false}
                    />
                    : null
                }

            </div>
    )
}

export default StartCharging