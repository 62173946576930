import { login } from 'reducers/auth';
import { addNotification } from 'reducers/notifications';
 

export const handleBackendError = (error, msgId, success) => {

  const errorCode = error.status_code
  const messageId = `${msgId}.${errorCode}`

  return addNotification({
      id: 'backend_error_notification',
      error: true,
      autoClear: true,
      messageId: messageId,
      success: false
    });
};
