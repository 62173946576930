export const SET_USER_INFO = 'SET_USER_INFO';
export const GET_USER_INFO = 'GET_USER_INFO';


const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  activeSession: false,
  authorizationReference: "",
  chargerCode: "",
  physicalReference: ""
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return{
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        activeSession: action.payload.activeSession,
        chargerCode: action.payload.code,
        physicalReference: action.payload.physicalReference,
        authorizationReference: action.payload.authorizationReference
      }
    default:
      return state;
  }
}

export const getUserInfo = (token, userEmail, userPassword) => ({
  type: GET_USER_INFO,
  token: token,
  userEmail: userEmail,
  userPassword: userPassword
});