import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { get, post, deleteHttp, putHttp } from 'utils/http';
import { handleBackendError } from 'utils/errorHandling';

import {
    API_EVSE
  } from 'constants.js';

  import {
    CODE_SUBMIT,
    STATION_INFO_SET,
    IS_VALID_SET,
    SET_IS_VALID
  } from 'reducers/station';

  import {
    NOTIFICATIONS_REMOVE_ALL
  } from 'reducers/notifications';

function* sendCode({code}) {

  let expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  let regex = new RegExp(expression)
  let qrCode;
  let userToken = localStorage.getItem('token')
  let header = {
    "Authorization" : `Bearer ${userToken}`
  }

  
  if(code.match(regex)){
    const url =  new URL(code)
    const searchParams = new URLSearchParams(url.search)
    qrCode = `${searchParams.get('evse')}${url.hash}`
  } else {
    qrCode = code
  }

  if(!(/[`~,<>;'"/[\]|{}()=+]/.test(qrCode))){
    try{
      const response = yield call(get, `${API_EVSE}/${encodeURIComponent(qrCode)}`, header);
      localStorage.setItem('physicalReference', response.physicalReference);
      localStorage.setItem('status', response.chargerStatus);
      localStorage.setItem('code', qrCode);

      yield put({
        type: NOTIFICATIONS_REMOVE_ALL,
      })
      
      yield put({
        type: STATION_INFO_SET,
        payload: {
          code: qrCode,
          validCode: true,
          floorLevel: response.floorLevel,
          physicalReference: response.physicalReference,
          status: response.chargerStatus
        }
      })
    } catch (error) {
      yield put({
        type: STATION_INFO_SET,
        payload: {
          validCode: false,
        }
      })
      yield put(handleBackendError(yield (error), "error.qr.label" || {}));
    }
  } else {
    yield put(handleBackendError(yield ({status_code: "2001"}), "error.qr.label" || {}));
  }
}

function* setIsValid(){
  yield put({
    type: IS_VALID_SET,
    payload: false
  })
}

function* watchSendCode() {
  yield takeLatest(CODE_SUBMIT, sendCode);
}

function* watchSetIsValid() {
  yield takeLatest(SET_IS_VALID, setIsValid);
}


export default function* root() {
    yield all([
      fork(watchSendCode),
      fork(watchSetIsValid)
    ]);
  }