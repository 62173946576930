import React, { useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useIntl } from 'react-intl';
import { Redirect, Route } from 'react-router-dom';
import BulChargeLogo from 'assets/logo/mainLogo.png'

import {
    HOME_PATH
} from 'constants.js'

import styles from './index.module.scss'

const KeycloakLoginButton = () => {

    const intl = useIntl();

    const { keycloak } = useKeycloak()

    const handleClick = () => {
        const url = new URL(window.location.href)
        const searchParams = new URLSearchParams(url.search)
        localStorage.setItem('scannedQRLink', `${searchParams.get('evse')}${url.hash}`)
        localStorage.setItem('loggedIn', 'true')
        keycloak.login();
    }

    useEffect(() => {

        if(!localStorage.getItem('token')){
            localStorage.removeItem('loggedIn')
        }

      }, []);
    
    return (
        (window.location.pathname !== "/profile" && localStorage.getItem('loggedIn') !== "true")
        ?<div className={styles.loginContainer} onClick={handleClick}>
            <img className={styles.logo} src={BulChargeLogo} alt="bulcharge logo"/>
            <p className={styles.tap}>{intl.formatMessage({id : "general.label.tap"})}</p>
        </div>
        :<Route><Redirect to={HOME_PATH}/></Route>
    )
}

export default KeycloakLoginButton