import React from "react";

import styles from "./index.module.scss"

const BowiTextField = ({type, label, onChange, handleKeyDown, keyHint}) => {
    return(
        <div className={styles.formGroup}>
            <input 
                type={type} 
                className={styles.formField} 
                placeholder={label} 
                name={label} 
                id={label}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                enterKeyHint={keyHint}
                required 
            />
            <label 
                htmlFor={label} 
                className={styles.formLabel}
            >{label}</label>
        </div>
    )
}

export default BowiTextField;