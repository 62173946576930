import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { get, post, deleteHttp, putHttp } from 'utils/http';
import { handleBackendError } from 'utils/errorHandling';

import {
    API_EVSE
  } from 'constants.js';

  import {
    START_CHARGING,
    STOP_CHARGING,
    SET_REFERENCE,
    SET_CHARGING_STOP,
    UPDATE_CHARGING_DATA,
    CHARGING_INFO_SET
  } from 'reducers/charging';

  import{
    IS_CHARGING
  } from 'reducers/station'


function* startCharging({token, code, hours, minutes}) {
  let header = {
    "Authorization" : `Bearer ${token}`
  }
  const parsedHours = parseInt(hours === null ? 0 : hours)
  const parsedMinutes = parseInt(minutes === null ? 0 : minutes)

  let body ={
    "code": `${code}`,
    "hours": parsedHours,
    "minutes": parsedMinutes,
    "unlimited": parsedHours === 0 && parsedMinutes === 0 ? true : false
  }

  try{
    const response = yield call(post, `${API_EVSE}/${encodeURIComponent(code)}/start`, JSON.stringify(body), header)
    
    localStorage.setItem('reference', response.reference);
    localStorage.setItem('isCharging', true);

    yield put({
      type: SET_REFERENCE,
      payload: response.reference
    })

    yield put({
      type: IS_CHARGING,
      payload: true
    })
  }catch (error){
    yield put(handleBackendError(yield (error), "error.startCharging.label" || {}));
  }
}

function* stopCharging({token, code, reference}) {
  let header = {
    "Authorization" : `Bearer ${token}`
  }

  try{
    const response = yield call(post, `${API_EVSE}/${encodeURIComponent(code)}/stop/${reference}`, null, header)
    localStorage.removeItem('physicalReference');
    localStorage.removeItem('status');
    localStorage.removeItem('hours');
    localStorage.removeItem('minutes');
    localStorage.removeItem('isCharging');
    
    yield put({
      type: SET_CHARGING_STOP,
      payload: true
    })
  }catch (error){
    yield put(handleBackendError(yield (error), "error.stopCharging.label" || {}));
  }
}

function* updateChargingData({reference, code}) {
  const token = localStorage.getItem('token')
  let header = {
    "Authorization" : `Bearer ${token}`
  }

  try{
    const response = yield call(get, `${API_EVSE}/${encodeURIComponent(code)}/details/${reference}`, header)

    yield put({
      type: CHARGING_INFO_SET,
      payload:{
        status: response.sessionStatus,
        energy: response.energy,
        connected: response.connected,
        hideStopButton: response.hideStopButton,
        sessionEnded: response.sessionEnded,
        finishes: response.finishes
      }
    })
  }catch (error){
    yield put(handleBackendError(yield (error), "error.detailsWhileCharging.label" || {}));
  }
}

function* watchStartCharging() {
  yield takeLatest(START_CHARGING, startCharging);
}

function* watchStopCharging() {
  yield takeLatest(STOP_CHARGING, stopCharging)
}

function* watchUpdateChargingData() {
  yield takeLatest(UPDATE_CHARGING_DATA, updateChargingData)
}

export default function* root() {
    yield all([
      fork(watchStartCharging),
      fork(watchUpdateChargingData),
      fork(watchStopCharging)
    ]);
  }