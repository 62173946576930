import React, { useEffect, useState } from 'react';
import Auth from 'components/Auth';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import OnLoadingComponent from 'components/OnLoadingComponent'
import ButtonKeyCloak from "components/KeycloakLoginButton"

import {
  HOME_PATH
} from "constants.js"

const AuthWrapper = (props) => {
  const {
    token,
    firtLogin,
    loggedIn,
    children,
    authenticate,
    getUserInfo
  } = props;


useEffect(() => {
  authenticate(localStorage.getItem('token'))
}, []);


return(
  React.cloneElement(children)
  )
}

export default AuthWrapper;
