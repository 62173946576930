import React from 'react';
import { useIntl } from 'react-intl'
import Notification from './Notification';

const Notifications = ({ className, notifications = [], onClose }) => {

  const intl = useIntl()

return(
    <div className={className}>
      { notifications.map(({ id, error, messageId, success }) =>
        <Notification
          key={id}
          error={error}
          message={intl.formatMessage({id : `${messageId}`})}
          messageId={messageId}
          onClose={() => onClose(id)}
          success={success}
        />
      ) }
    </div>
  )
}

export default Notifications;
