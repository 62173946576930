import React from "react";
import Invoice from "containers/Invoice"

import styles from "./index.module.scss"

const InvoicePage = () => {

    return(
        <div className={styles.container}>
            <Invoice/>
        </div>
    )
}

export default InvoicePage