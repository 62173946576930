import auth from './auth';
import station from './station';
import notifications from './notifications';
import user from './user';
import invoices from './invoices';
import charging from './charging';


export default {
  auth,
  station,
  charging,
  user,
  invoices,
  notifications
}
