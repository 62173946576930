import { connect } from 'react-redux';
import FooterMenu from 'components/FooterMenu';

import { 
  isValidSet
} from 'reducers/station'

export default connect(
  ({ station: {}}) => ({}),
  {
    setIsValid: isValidSet
  }
)(FooterMenu);

