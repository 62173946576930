import React, {useState, useEffect, isValidElement} from 'react';
import { useIntl } from 'react-intl';
import QrCode from 'assets/icons/qr.svg'
import BowiButton from  'components/BowiButton'
import BowiLink from 'components/BowiLink'
import BowiTextField from 'components/BowiTextField';
import QrScanner from 'containers/QrScanner'
import { Redirect, Route } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';


import styles from './index.module.scss'


//import constants
import {
  TUTORIAL_PATH,
  CHARGER_PATH,
} from "constants.js";

const Home = ({submitCode, validCode, activeSession, chargerCode, getUserInfo}) => {

  const intl = useIntl();
  const [scanning, setStartScannig] = useState(false)
  const [code, setCode] = useState('')
  const [codeSubmitted, setCodeSubmitted] = useState(false)
  const [firstLogin, setFirstLogin]= useState(false)
  const [visibleSpinner, setVisibleSpinner] = useState(false)

  const handleTimeOut = () => {
    const timer = setTimeout(() => {
      setVisibleSpinner(false)
    }, 3500);
    return () => clearTimeout(timer);
  }

  useEffect(() => {
    let firstLogin = localStorage.getItem("firstLogin")
    if(firstLogin === "true"){
      setFirstLogin(true)
      localStorage.setItem('firstLogin', false);
    }

    handleScannedQR()
    let scannedQRLink = localStorage.getItem('scannedQRLink')
    let userToken = localStorage.getItem('token')
    if (scannedQRLink !== (null || "null") && userToken) {
      submitCode(scannedQRLink)
      setCodeSubmitted(validCode)
      setVisibleSpinner(true)
      handleTimeOut()
    } 
  }, []);

  const handleScannedQR = () => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)
    localStorage.setItem('scannedQRLink', `${searchParams.get('evse')}${url.hash}`)
  }

  const handleScanning = () => {
    setStartScannig(!scanning)
  }
  
  const handleOnKeyDown = (event) => {
    if(event.key === 'Enter'){
      submitCode(code)
      setVisibleSpinner(true)
      setTimeout(
        function() {
          setVisibleSpinner(false);
        },
        1000
      );
      setCodeSubmitted(validCode)
    }
  }

  const onChangeHndler = (event) => {
    setCode(event.target.value)
  }

  return(activeSession && localStorage.getItem('reference')
    ? <Route><Redirect to={CHARGER_PATH}/></Route>
    :firstLogin
    ? <Route><Redirect to={TUTORIAL_PATH}/></Route>
    : !scanning  
      ? <div className={styles.wrapper}>
          <p className={styles.title}>{intl.formatMessage({id : "homepage.label.startCharging"})}</p>
          <div className={styles.icon}>
            <img className={styles.image} src={QrCode} alt="qrcode"/>
          </div>
          <BowiButton
            handleClick = {handleScanning}
            className={styles.button}
            type="primary"
            content={intl.formatMessage({id : "homepage.label.scanQr"})}
          />
          <div className={styles.descriptionWrapper}> 
            <p className={styles.description}>{intl.formatMessage({id : "homepage.label.scanProblem"})}</p>
            <p className={styles.description}>{intl.formatMessage({id : "homepage.label.enterCode"})}</p>
          </div>
          <BowiTextField
            onChange={onChangeHndler}
            handleKeyDown={handleOnKeyDown}
            type="text"
            name="code"
            label={intl.formatMessage({ id: "general.label.code"})}
            className={styles.input}
            keyHint="search"
          />
          {validCode
            ?<Route><Redirect to={CHARGER_PATH}/></Route>
            : visibleSpinner
              ?<div className={styles.spinnerWrapper}>
                  <div className={styles.spinner}>
                    <CircularProgress
                      color="primary"
                      size={80}
                    />
                  </div>
                </div>
              : null
              }
         
        </div>
      :  <QrScanner handleScanning={handleScanning}></QrScanner>
    )
}

export default Home;