import React, {useState, useEffect} from 'react'
import StartCharging from 'containers/StartCharging'
import StopCharging from 'containers/StopCharging'
import { Redirect, Route } from 'react-router-dom';
import { 
    HOME_PATH,
} from 'constants.js';

import styles from './index.module.scss'


const Charger = ({setIsValid, isCharging, physicalReference}) => {

    const [isConnected, setIsConnected]= useState(true);
    const [isAvailable, setIsAvailable]= useState(true);
    const [isOpen, setIsOpen]= useState(false);

    const lsIsCharging = localStorage.getItem('isCharging')
    const flag = lsIsCharging ? !lsIsCharging : !isCharging

    useEffect(() => {
        localStorage.removeItem('scannedQRLink')
        let physicalRef = physicalReference ? physicalReference : localStorage.getItem('physicalReference');
        let status = localStorage.getItem('status')
        if (physicalRef === null) {
            setIsConnected(false)
        }
        if (status !== "AVAILABLE") {
            setIsAvailable(false)
            setIsOpen(true)
        }
        handleTimeOut()
      }, []);

    const handleCloseError = () => {
        setIsOpen(!isOpen)
        handleTimeOut()
    }

    const handleTimeOut = () => {
        const timer = setTimeout(() => {
            setIsOpen(false)
        }, 3500);
        return () => clearTimeout(timer);
    }

    return(
        isConnected
        ?(flag
            ? <StartCharging 
                isAvailable={isAvailable} 
                isOpen={isOpen} 
                handleCloseError={handleCloseError}
                />
            : <StopCharging
                setIsValid={setIsValid}
                />
        ):<Route><Redirect to={HOME_PATH}/></Route>
    )
} 

export default Charger