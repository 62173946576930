export const AUTH_AUTHENTICATE = 'AUTH_AUTHENTICATE';
export const AUTH_CLEAR_STATE = 'AUTH_CLEAR_STATE';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SET_STATUS = 'AUTH_SET_STATUS';
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_SET_LOGIN = 'AUTH_SET_LOGIN';

const initialState = {
  token: '',
  expires: '',
  firstLogin: false,
  loggedIn: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SET_STATUS:
      return {
        ...state,
        ...action.payload
      };
    case AUTH_CLEAR_STATE:
      return initialState;
    case AUTH_SET_LOGIN:
      return{
        ...state,
        firstLogin: action.payload.firstLogin,
        loggedIn: action.payload.loggedIn
      }
    case AUTH_SET_TOKEN:
      return{
        ...state,
        token: action.payload.token,
        expires: action.payload.expires
      }
    default:
      return state;
  }
}

export const authLogin = (userToken, expires) => ({
  type: AUTH_AUTHENTICATE,
  userToken: userToken,
  expires: expires
});

export const authLogout = () => ({
  type: AUTH_LOGOUT
});

export const login = () => ({
  type: AUTH_LOGIN
});
