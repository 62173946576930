import React , {useState, useEffect} from 'react'
import StopChargerHeader from 'containers/StopChargerHeader'
import ChargingAnimation from 'assets/animation/charging-animation.gif'
import BowiButton from 'components/BowiButton'
import Processing from 'components/Processing'
import ChargingInfo from 'components/ChargingInfo'
import { Redirect, Route } from 'react-router-dom';
import {useIntl} from 'react-intl'
import LinearProgress from '@material-ui/core/LinearProgress'
import useInterval from 'utils/useInterval'
import Notification from 'components/Notifications/Notification'


import {
    INVOICE_PATH,
    CHARGER_PATH
} from 'constants.js'


import styles from './index.module.scss'

const StopCharging = ({
    status,
    energy,
    connected,
    finishes,
    hideStopButton,
    sessionEnded,
    reference,
    chargingStop,
    handleStopCharging,
    updateChargingData,
    chargerCode,
    activeSession
}) => {

    const intl = useIntl()
    const lsCode = localStorage.getItem("code")
    const lsReference = localStorage.getItem("reference")

    const[isOpen, setIsOpen] = useState(false)

    const handleStop = () => {
        let code = localStorage.getItem("code")
        let token = localStorage.getItem("token")
        let reference = localStorage.getItem("reference")
        handleStopCharging(token, code, reference)
        setIsOpen(true)
    }

    const handleCloseError = () => {
        setIsOpen(false)
    }

    useEffect(() => {
        if(chargerCode || lsCode){
            updateChargingData(reference ? reference : lsReference, chargerCode ? chargerCode : lsCode )
        }
    }, []);

    useInterval(() => {
        if(chargerCode || lsCode ){
            updateChargingData(reference ? reference : lsReference, chargerCode ? chargerCode : lsCode)
        }
    }, 5000);

    return((sessionEnded && status === "COMPLETED") || status === "INVALID"
        ?<Route><Redirect to={INVOICE_PATH}/></Route>
        :<div className={styles.container}>
            <StopChargerHeader/>
            {!sessionEnded
                ?<img src={ChargingAnimation} alt="chargin-animation"/>
                : <Processing />
            }
            <div className={styles.buttonContainer}>
            {status === "PENDING" || status === ""
                ? <div className={styles.progressBarContainer}>
                    <LinearProgress color="secondary" className={styles.progressBar}/>
                </div>
                : <ChargingInfo
                    energy={energy}
                    connected={connected}
                    finishes={finishes}
                />
            }
                {hideStopButton || status !== "PENDING" && status != ""
                    ?<BowiButton
                        handleClick={handleStop}
                        type={!sessionEnded ? "secondary" : "disabledSecondary"}
                        content={intl.formatMessage({id : "charging.lable.stop"})}               
                    />
                    :null
                }
                {(sessionEnded || isOpen) && status !== "PENDING"
                    ? <Notification
                        className={styles.notification}
                        error={false}
                        message={intl.formatMessage({id: "info.stopCharging.stopped"})}
                        success={false}
                        onClose={handleCloseError}
                    />
                    : null
                }

            </div>
        </div>
    )
}

export default StopCharging