import React, {Component} from "react";
import Banner from 'components/Banner'
import FooterMenu from 'containers/FooterMenu'

import styles from './index.module.scss'

class Layout extends Component {
    render() {
        return (
            <div className={styles.wrapper}>
                <Banner />
                {this.props.children}
                <FooterMenu />
            </div>
        )
    }
}

export default Layout