import Keycloak from 'keycloak-js';

import {
    API_ATUH_KC,
  } from "constants.js"

const keycloak = Keycloak({
    url: API_ATUH_KC,
    realm: "SpringBootKeycloak",
    clientId: "bulcharge",
    onLoad: 'login-required'
});

export default keycloak;