import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';

import {
  TUTORIAL_PATH
} from 'constants.js'

import styles from './index.module.scss'

class BowiBanner extends React.Component {
    render() {
      return (
        <div className={styles.banner}>
            <div><span>J</span>-Charge</div>
        </div>
      );
    }
  }


const Banner = () => {

  const [prevLoggedIn, setPrevLoggedIn] = useState(true)

  useEffect(() => {
    let token = localStorage.getItem("token");
      if (token === null) {
        setPrevLoggedIn(false);
      }
    }, []);

    const location = useLocation();

    useEffect(() => {
      }, [location])

    return prevLoggedIn 
        ? <div>
            {location.pathname !== TUTORIAL_PATH
            ? <BowiBanner/>
            : null }
        </div>
        : null
}

export default Banner