import { connect } from 'react-redux';
import ProfileView from 'components/ProfileView';

export default connect(
  ({ user: {
    firstName,
    lastName,
    email
  } }) => ({
    firstName,
    lastName,
    email
  })
)(ProfileView);
