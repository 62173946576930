import React, { useEffect, useState } from "react";
import InvoiceIcon from "assets/icons/invoice.svg"
import { useIntl, FormattedNumber } from "react-intl"
import BowiButton from "components/BowiButton"
import { Redirect, Route } from 'react-router-dom';
import { 
    HOME_PATH 
} from "constants.js";

import styles from "./index.module.scss"



const Invoice = ({setIsValid, updateChargingStatus, clearIsCharging, getInvoiceInfo, currentInvoice, authorizationReference, chargerCode, getUserInfo, resetChargingDataToInitialState}) => {
    
    useEffect(() => {
        let token = localStorage.getItem('token');
        let reference = authorizationReference ? authorizationReference : localStorage.getItem('reference');
        let code = chargerCode ? chargerCode : localStorage.getItem('code');
        getInvoiceInfo(token, code, reference)
    },[])

    const intl = useIntl();
    const [redirect, setRedirect] = useState(false)

    const clearChargingCache = () => {
        localStorage.removeItem('status')
        localStorage.removeItem('code')
        localStorage.removeItem('isCharging')
        localStorage.removeItem('reference')
        localStorage.removeItem('physicalReference')
    }

    const handleReturn = () => {
        resetChargingDataToInitialState()
        getUserInfo(localStorage.getItem('token'), null, null)
        setIsValid()
        updateChargingStatus()
        clearIsCharging()
        clearChargingCache()
        handleRedirect()
    }

    const handleRedirect = () => {
        setRedirect(true)
    }

    return(!redirect
        ?<div className={styles.container}>
            <img className={styles.icon} src={InvoiceIcon} alt="invoice-icon"/>
            <p className={styles.title}>{intl.formatMessage({id : "invoice.label.title"})}</p>
            <div className={styles.details}>
                <div className={styles.detailContent}>
                    <p className={styles.title}>{intl.formatMessage({id : "invoice.label.energy"})}</p>
                    <p>{currentInvoice.energy}</p>
                </div>
                <div className={styles.detailContent}>
                    <p className={styles.title}>{intl.formatMessage({id : "invoice.label.connected"})}</p>
                    <p>{currentInvoice.connected}</p>
                </div>
                <div className={styles.detailContent}>
                    <p className={styles.title}>{intl.formatMessage({id : "invoice.label.finished"})}</p>
                    <p>{currentInvoice.finished}</p>
                </div>
                <div className={styles.detailContent}>
                    <p className={styles.title}>{intl.formatMessage({id : "invoice.label.price"})}</p>
                    <p><FormattedNumber 
                        value={currentInvoice.price} 
                        style="currency" 
                        currency={currentInvoice.currency}
                    /></p>
                </div>
            </div>
            <div className={styles.buttonContainer}>
                <BowiButton
                    type="secondary"
                    content={intl.formatMessage({id : "invoice.label.return"})}
                    handleClick={handleReturn}
                />
            </div>
        </div>
        :<Route><Redirect to={HOME_PATH}/></Route>
    )
}

export default Invoice