import { connect } from 'react-redux';
import Home from 'pages/Home';

import { 
  sendCode,
  isValidSet
} from 'reducers/station'

import { 
  getUserInfo,
} from 'reducers/user'

export default connect(
  ({ station: {
    validCode
    },
    user:{
    activeSession,
    chargerCode,
    },
  }) => ({
    validCode,
    activeSession,
    chargerCode,
  }),
  {
    submitCode: sendCode,
    isValidSet: isValidSet,
    getUserInfo: getUserInfo
  }
)(Home);

