//App Constants

//App Routes
export const HOME_PATH = "/"
export const SELECT_STATION_PATH = "/selectstation"
export const PROFILE_PATH = "/profile"
export const STATISTICS_PATH = "/statistics"
export const PAYMENTS_PATH = "/payments"
export const CHARGER_PATH = "/charger"
export const INVOICE_PATH = "/invoice"
export const TUTORIAL_PATH = "/tutorial"
export const BUY_CHARGE_POINTS_PATH = "/buychargepoints"
export const AUTH_PATH ="/auth"

//App API
export const API_ROUTE = `https://bulcharge-develop.jibecompany.com/api`
export const API_AUTH = `${API_ROUTE}/account/login`
export const API_EVSE = `${API_ROUTE}/charge/evses`
export const API_USER = `${API_ROUTE}/users/details`
export const API_ATUH_KC = `https://bulcharge-account-dev.jibecompany.com/auth/`

// Component logic
export const NOTIFICATION_TIMEOUT = 3500;