import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { get, post, deleteHttp, putHttp } from 'utils/http';
import { handleBackendError } from 'utils/errorHandling';
import {
  API_EVSE
} from 'constants.js';
import {
  GET_INVOICE_INFO,
  SET_INVOICE_INFO
} from 'reducers/invoices';
import {
  NOTIFICATIONS_REMOVE_ALL
} from 'reducers/notifications';

function* fetchInvoiceInfo({token, code, reference}) {

  let header = {}
  
  if(token !== null){
    header = {
      "Authorization" : `Bearer ${token}`
    }
  }

  try{

    const response = yield call(get, `${API_EVSE}/${encodeURIComponent(code)}/details/${reference}/finish`, header)

    yield put({
      type: SET_INVOICE_INFO,
      payload: {
        energy: response.energy,
        connected: response.connected,
        finished: response.finished,
        price: response.price,
        currency: response.currency
      } 
    })

  } catch (error) {
    yield put(handleBackendError(yield ({status_code: "2001"}), "error.qr.label" || {}));
  }
}

function* watchGetInvoiceInfo() {
  yield takeLatest(GET_INVOICE_INFO, fetchInvoiceInfo);
}

export default function* root() {
    yield all([
      fork(watchGetInvoiceInfo)
    ]);
  }