export const CODE_SUBMIT = 'CODE_SUBMIT';
export const IS_VALID_SET = 'IS_VALID_SET';
export const SET_IS_VALID = 'SET_IS_VALID';
export const STATION_INFO_SET = 'STATION_INFO_SET';
export const IS_CHARGING = 'IS_CHARGING';

const initialState = {
  code: '',
  validCode: false,
  floorLevel: '',
  physicalReference: '',
  status: '',
  isCharging: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STATION_INFO_SET:
      return{
        ...state,
        code: action.payload.code,
        floorLevel: action.payload.floorLevel,
        physicalReference: action.payload.physicalReference,
        status: action.payload.status,
        validCode: action.payload.validCode
      }
    case IS_VALID_SET:
      return{
        ...state,
          validCode: action.payload
        }
    case IS_CHARGING:
      return{
        ...state,
        isCharging: action.payload
      }
    default:
      return state;
  }
}

export const sendCode = (code) => ({
  type: CODE_SUBMIT,
  code: code,
});

export const isValidSet = () => ({
  type: SET_IS_VALID
})

export const clearIsCharging = () => ({
  type: IS_CHARGING,
  payload: false
})
